import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="display-email"
export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!")
  }
  static targets = [ "mail" ]

  display (event) {
    event.preventDefault();
    console.log(this.mailTarget)
    fetch(this.mailTarget.action, {
      method: 'POST',
      headers: {
        'Accept': 'application/json' },
      body: JSON.stringify({email: this.mailTarget.value})
    })
      .then(response =>response.json())
      .then((data) => {
        console.log(data)})
  }
}
